import * as React from "react"

const DSEPage = () => {
    return (
        <html>
            <head>
                <title>Datenschutzerkl&auml;rung</title>
            </head>
            <body>
                <h1>Datenschutzerkl&auml;rung</h1>
            </body>
        </html>
    );
}

export default DSEPage